import React from 'react';
import { graphql } from 'gatsby';
import { SEO, Layout, Hero } from 'components';
import { MainTitle, Container, Title } from 'ui';
import styled from 'styled-components';
import { Stack } from '@tymate/margaret';
import Section from 'components/Section';
import LawyersList from 'components/LawyersList';
import StructuredText from 'components/StructuredText';
import { MdChevronRight } from 'react-icons/md';
import logoPhilaw from 'images/logo-white.svg';
import notariesFromFrance from 'images/notaires-de-france.png';

const ListElement = styled.li`
  color: ${({ theme }) => theme.textOnDarkLighter};
  transition: color 150ms ease;

  &:hover {
    color: ${({ theme }) => theme.textOnDark};
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;

const List = styled.ul`
  margin: 0;
  margin-top: ${({ theme }) => theme.spacing(2)};
  padding: 0;
  list-style-type: none;
  color: #ffffff;

  li + li {
    margin-top: ${({ theme }) => theme.spacing()};
  }
`;

const NotarialOffice = ({
  data: { datoCmsPageNotaryOffice, allDatoCmsNotary },
}) => {
  return (
    <>
      <SEO
        title={datoCmsPageNotaryOffice?.metaTags?.title}
        description={datoCmsPageNotaryOffice?.metaTags?.description}
        notarialPage
      />

      <Layout
        hero={
          <Hero cover={datoCmsPageNotaryOffice?.heroImage}>
            <Stack
              direction="column"
              alignX="center"
              alignY="center"
              gutterSize={0.5}
              css={`
                flex: 1;
              `}
            >
              <img
                width={200}
                height={200}
                src={datoCmsPageNotaryOffice?.heroIcon?.url}
                alt=""
              />
              <MainTitle as="div" variant="light">
                {datoCmsPageNotaryOffice.heroTitle}
              </MainTitle>
            </Stack>
          </Hero>
        }
      >
        <Section topDecorationColor="primary" bottomDecorationColor="primary">
          <Stack direction="column" gutterSize={2} size="full" alignX="stretch">
            <Container>
              <MainTitle as="h2" variant="primary">
                <strong>{datoCmsPageNotaryOffice?.teamTitle}</strong>
              </MainTitle>
            </Container>
            <LawyersList lawyers={allDatoCmsNotary?.edges || []} />
            <Container>
              <div
                style={{
                  maxWidth: 800,
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  textAlign: 'center',
                }}
              >
                {datoCmsPageNotaryOffice?.teamText && (
                  <StructuredText data={datoCmsPageNotaryOffice?.teamText} />
                )}
              </div>
            </Container>
          </Stack>
        </Section>

        <Section
          variant="primaryDark"
          topDecorationColor="white"
          bottomDecorationColor="white"
        >
          <Container>
            <Stack
              direction="column"
              alignX="center"
              size="full"
              paddingVertical={2}
              gutterSize={1}
            >
              <img
                width={200}
                height={200}
                src={datoCmsPageNotaryOffice?.tarifIcon?.url}
                alt=""
              />
              <Title variant="light">
                {datoCmsPageNotaryOffice?.tarifTitle}
              </Title>
              <List>
                {(datoCmsPageNotaryOffice.textAndPdfBlock || []).map(
                  ({ id, text, pdf }) => (
                    <ListElement key={id}>
                      <MdChevronRight />
                      <a
                        href={pdf?.url}
                        rel="nofollow noopener noreferrer"
                        target="_blank"
                      >
                        {text}
                      </a>
                    </ListElement>
                  ),
                )}
              </List>
            </Stack>
          </Container>
        </Section>

        {datoCmsPageNotaryOffice?.customText && (
          <Section topDecorationColor="primary" bottomDecorationColor="primary">
            <Stack
              direction="column"
              gutterSize={2}
              size="full"
              alignX="stretch"
            >
              <Container>
                <StructuredText data={datoCmsPageNotaryOffice?.customText} />
              </Container>
            </Stack>
          </Section>
        )}

        <Section
          variant="primaryDark"
          topDecorationColor="white"
          bottomDecorationColor="white"
        >
          <Container>
            <Stack
              alignY="center"
              alignX="center"
              gutterSize={6}
              style={{ paddingLeft: '47px' }}
            >
              <img src={logoPhilaw} alt="Logo philaw" />
              <img
                style={{ transform: 'translateY(-2px)' }}
                src={notariesFromFrance}
                height={280}
                weight={280}
                alt="Logo notaire"
              />
            </Stack>
          </Container>
        </Section>
      </Layout>
    </>
  );
};

export const query = graphql`
  query officeNotarial {
    datoCmsPageNotaryOffice {
      id
      metaTags {
        title
        description
      }
      heroTitle
      heroImage {
        gatsbyImageData(aspectRatio: 1.77, layout: FULL_WIDTH)
      }
      heroIcon {
        url
      }
      teamTitle
      teamText {
        value
      }
      tarifIcon {
        url
      }
      tarifTitle
      textAndPdfBlock {
        id
        text
        pdf {
          url
        }
      }
      customText {
        value
      }
    }
    allDatoCmsNotary(sort: { fields: order }) {
      edges {
        node {
          id
          name
          description
          linkedinLink
          picture {
            gatsbyImageData(aspectRatio: 0.737)
          }
        }
      }
    }
  }
`;

export default NotarialOffice;
